
<template>
  <div>
    <Terminal
      welcomeMessage="ENAT.AT Klimacomputer // Terminal"
      prompt="klimacomputer $"
      class="dark-demo-terminal"
    />
  </div>
</template>

<script>
import TerminalService from "primevue/terminalservice";

export default {
	name: "theTerminal",
  methods: {
    commandHandler(text) {
      let response;
      let argsIndex = text.indexOf(" ");
      let command = argsIndex !== -1 ? text.substring(0, argsIndex) : text;

      switch (command) {
        case "date":
          response = "Today is " + new Date().toDateString();
          break;

        case "greet":
          response = "Hola " + text.substring(argsIndex + 1);
          break;

        case "random":
          response = Math.floor(Math.random() * 100);
          break;

        default:
          response = "Unknown command: " + command;
      }

      TerminalService.emit("response", response);
    },
  },
  mounted() {
    TerminalService.on("command", this.commandHandler);
  },
  beforeUnmount() {
    TerminalService.off("command", this.commandHandler);
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-top: 0;
}

::v-deep(.dark-demo-terminal) {
  background-color: #212121;
  color: red;

  .p-terminal-command {
    color: #80cbc4;
  }

  .p-terminal-prompt {
    color: #ffd54f;
  }

  .p-terminal-response {
    color: #9fa8da;
  }
}
</style>